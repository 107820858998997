import { render, staticRenderFns } from "./UpdateProfileModal.vue?vue&type=template&id=286c4416&scoped=true"
import script from "./UpdateProfileModal.vue?vue&type=script&lang=js"
export * from "./UpdateProfileModal.vue?vue&type=script&lang=js"
import style0 from "./UpdateProfileModal.vue?vue&type=style&index=0&id=286c4416&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286c4416",
  null
  
)

export default component.exports